import React from "react"
import { Link } from "gatsby"
import * as styles from "./readmore.module.css"

function ReadMore({ slug }) {
  return (
    <div className={styles.readMoreContainer}>
      <Link to={`/blog/${slug}`} className={styles.readMore}>
        Read more <span>➔</span>
      </Link>
    </div>
  )
}

export default ReadMore
