import React from "react"
import { Link } from "gatsby"
import BadgeList from "./Badge/BadgeList"
import ReadMore from "./ReadMore/ReadMore"

function Blog({ blog }) {
  const { title, categories, slug, date } = blog.frontmatter
  const { children } = blog.htmlAst
  let introText = ""
  let blogDate = new Date(date)
  if (children[0]) {
    introText = children[0].children[0].value
  }
  return (
    <div
      style={{
        width: "100%",
        minWidth: "0",
        maxWidth: "680px",
        margin: "0 64px",
      }}
    >
      <article>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            boxSizing: "content-box",
            marginBottom: "50px",
            marginTop: "40px",
          }}
        >
          <div
            style={{
              fontStyle: "normal",
              fontWeight: "700",
              marginBottom: "-0.27em",
              marginTop: "0.6em",
              lineHeight: "36px",
              fontSize: "24px",
              letterSpacing: "-0.014em",
            }}
          >
            <Link to={`/blog/${slug}`}>{title || "UNTITLE"}</Link>
          </div>
          <BadgeList categories={categories} />
          <div>
            <p
              style={{
                marginBottom: "0.46em",
                fontSize: "21px",
                marginTop: "2em",
                lineHeight: "32px",
                letterSpacing: "-0.003em",
              }}
            >
              {introText}
            </p>
            <ReadMore slug={slug} />
          </div>
        </div>
        <div>
          {blogDate.toLocaleString(`en-US`, {
            day: "2-digit",
            year: "numeric",
            month: "long",
          })}
        </div>
        <hr style={{ margin: "16px 0 0 0 " }} />
      </article>
    </div>
  )
}

export default Blog
