import React from "react"
import "../../styles/blog.module.css"
import * as styles from "../../styles/blog.module.css"
import BlogHeader from "../../components/Header/Blog/BlogHeader"
import BlogList from "../../components/Blog/BlogList"
import { graphql } from "gatsby"
function index({ data }) {
  const blogList = data.projects.nodes
  return (
    <div style={{ marginBottom: "5vh" }}>
      <BlogHeader />
      <BlogList blogList={blogList} />
    </div>
  )
}

export default index

// export page query
export const query = graphql`
  query BlogQuery {
    projects: allMarkdownRemark {
      nodes {
        id
        frontmatter {
          stack
          title
          slug
          date
          categories
        }
        htmlAst
      }
    }
  }
`
